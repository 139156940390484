<template>
  <section class="ficha" v-if="assetPrepare">
    <b-row >
      <b-col cols="12">
        <b-card no-body class="height-card position-relative px-2">
          <b-row class="my-2">
            <!-- Left: Product Image Container -->
            <b-col cols="12" lg="3" class="d-flex align-items-center justify-content-center mb-2 mb-md-0">
              <b-carousel id="carousel-fade" style="text-shadow: 0px 0px 2px #000" :indicators="images.length > 1"
                :interval="99999" img-width="1024" img-height="720">
                <b-carousel-slide v-for="image in images" :img-src="image.img"></b-carousel-slide>
              </b-carousel>
            </b-col>
            <!-- Right: Product Details -->
            <b-col cols="12" lg="9" class="ficha-info">
              <!-- Product Name -->
              <div class="mb-1 mt-1">
                <b-badge v-if="asset.status && asset.status !== 'free'" :variant="statusAssets[asset.status.alias]"
                  class="mr-50">
                  {{ $t(`status.assets.${asset.status.alias}`) }}
                </b-badge>
              </div>
              <div class="mb-1 mt-1">
                <h2 class="ficha-info_name mb-50">{{ asset.name }}</h2>
              </div>
              <div class="mb-2" v-if="asset.power">
                <b-badge variant="ligth" style="
                    border: 1px solid black;
                    color: black;
                    font-weight: 500;
                  ">
                  {{ $t("FacilityPower") }} {{ asset.power }}

                  <span v-if="!asset.power_type">Wp (vatio pico)</span>
                  <span v-else>
                    <span v-if="asset.power_type === 'MW'">MW ({{ $t("megavatio") }})</span>
                    <span v-if="asset.power_type === 'Wp'">Wp ({{ $t("vatiopico") }})</span>
                  </span>

                </b-badge>
              </div>
              <b-row class="mb-2" v-if="asset.status.alias == 'use' && asset.user">
                <b-col cols="12" xl="4" md="6" class="mb-2">
                  <div class="bg-light-fade p-1 h-100">
                    <div class="">
                      <b-row class="">
                        <b-link 
                          :to="{
                            name: 'viewUser',
                            params: { id: asset.user.id },
                          }" 
                          class="mr-1"
                        >
                          <b-col cols="12" class="d-flex align-items-center">
                            <div class="pr-2">
                              <b-avatar size="4rem" :src="asset.user.avatar_url" />
                            </div>
                            <div>
                              <h4 class="m-0 font-weight-bolder">
                                {{ asset.user.name }} {{ asset.user.surname }}
                              </h4>

                              <span :key="r.display_name" v-for="r in asset.user.roles">
                                {{ r.display_name }}
                              </span>
                            </div>
                          </b-col>
                        </b-link>
                      </b-row>
                    </div>
                  </div>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12" xl="4" md="6" class="mb-2 d-lg-block d-none">
                  <div class="bg-light-fade p-1 h-100">
                    <p class="font-weight-bolder mb-0">
                      {{ $t("FechaCompra") }}
                    </p>
                    <p class="mb-0" v-if="asset.purchase_date">
                      {{ toDate(asset.purchase_date) }}
                    </p>
                    <p class="mb-0" v-else>-</p>
                  </div>
                </b-col>
                <b-col cols="12" xl="4" md="6" class="mb-2">
                  <div class="bg-light-fade p-1 h-100">
                    <p class="font-weight-bolder mb-0">
                      {{ $t("UltimaRevision") }}
                    </p>
                    <p class="mb-0" v-if="asset.last_review_date">
                      {{ toDate(asset.last_review_date) }}
                    </p>
                    <p class="mb-0" v-else>-</p>
                  </div>
                </b-col>
                <b-col cols="12" xl="4" md="6" class="mb-2">
                  <span v-if="asset.next_review_date && asset.next_review_date != 'null'
    ">
                    <div v-if="asset.next_review_date < nowTimestamp" class="bg-danger-fade p-1 h-100">
                      <p class="font-weight-bolder mb-0">
                        {{ $t("ProximaRevision") }}
                      </p>
                      <p class="mb-0">{{ toDate(asset.next_review_date) }}</p>
                      <b-link 
                        v-if="asset.task_next_review_date && !appMode"
                        :to="{
                          name: 'viewTask',
                          params: { id: asset.task_next_review_date.id },
                        }" 
                        class="link-blue truncated-text mt-1"
                      >
                        {{ asset.task_next_review_date.name }}
                        <feather-icon size="18" icon="ArrowRightIcon" />
                      </b-link>
                    </div>
                    <div v-else class="bg-light-fade p-1 h-100">
                      <p class="font-weight-bolder mb-0">
                        {{ $t("ProximaRevision") }}
                      </p>
                      <p class="mb-0">{{ toDate(asset.next_review_date) }}</p>
                    </div>
                  </span>
                  <span v-else>
                    <div class="bg-light-fade p-1 h-100">
                      <p class="font-weight-bolder mb-0">
                        {{ $t("ProximaRevision") }}
                      </p>
                      <p class="mb-0">-</p>
                    </div>
                  </span>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <!-- CLIENT-INFO -->
      <b-col cols="12" v-if="asset.subclient">
        <b-card>
          <h4 class="font-weight-bolder">{{ $t("ClientInformation") }}</h4>
          <hr class="my-1" />
          <div class="pt-1">
            <span class="font-weight-bolder"> {{ $t("NombreCliente") }}: </span>
            <span>{{ asset.subclient.name }}</span>
          </div>
          <template v-if="clientContactPersons">
            <div class="py-1">
              <span class="font-weight-bolder">
                {{ $t("ContactPersons") }}:
              </span>
            </div>
            <b-row>
              <b-col v-for="contact in clientContactPersons" sm="12" md="4">
                <b-card style="background: #f7f5f0">
                  <div>
                    <span class="mr-1 font-weight-bolder">
                      {{ contact.name }}
                    </span>
                  </div>
                  <b-link 
                    v-if="contact.phone" :href="'tel:' + contact.phone" 
                    class="d-flex align-items-center link-blue"
                  >
                    <feather-icon size="18" icon="PhoneIcon" class="mr-50" />
                    <span class="mr-1">
                      {{ contact.phone }}
                    </span>
                  </b-link>
                  <b-link 
                    v-if="contact.email" 
                    :href="'mailto:' + contact.email" 
                    class="d-flex align-items-center link-blue"
                  >
                    <feather-icon size="18" icon="MailIcon" class="mr-50" />
                    <span class="mr-1">
                      {{ contact.email }}
                    </span>
                  </b-link>

                </b-card>
              </b-col>
            </b-row>
          </template>
        </b-card>
      </b-col>
      <!-- FACILITY INFO -->
      <b-col cols="12">
        <b-card>
          <h4 class="font-weight-bolder">{{ $t("DetallesAsset") }}</h4>
          <hr class="my-1" />
          <b-card v-if="asset.tag.image_url || asset.location || asset.location_relation
    " no-body bg-variant="light" :class="{ 'p-2': !['xs', 'sm'].includes(breakpoint) }">
            <b-row>
              <!-- QR -->
              <b-col v-if="asset.tag.image_url" sm="12" md="4" class="ficha-tag d-lg-block d-none">
                <b-card no-body style="height: 100%">
                  <div class="p-2" v-if="asset.tag">
                    <template v-if="asset.tag.image_url">
                      <h5 class="mb-1 font-weight-bolder">{{ $t("QR") }}</h5>
                      <div class="text-center mb-2">
                        <img :src="asset.tag.image_url" class="img-fluid" />
                      </div>
                    </template>
                    <p class="mb-50" v-if="asset.tag.last_scan">
                      <span class="font-weight-bolder">{{ $t("UltimoRegistro") }}:</span>
                      {{ toDateWithTime(asset.tag.last_scan) }}
                    </p>
                    <p v-if="asset.tag.total_scans" class="mb-50">
                      <span class="font-weight-bolder">{{ $t("TotalRegistros") }}:</span>
                      {{ asset.tag.total_scans }}
                    </p>
                  </div>
                </b-card>
              </b-col>
              <!-- LOCATION -->
              <b-col v-if="latitude && longitude" sm="12" md="8">
                <b-card no-body style="height: 100%">
                  <div :class="{ 'p-1': !['xs', 'sm'].includes(breakpoint) }">
                    <h5 class="mb-1 font-weight-bolder">
                      {{ $t("Localizacion") }}
                    </h5>
                    <p class="mb-1" v-if="locActual">{{ asset.location }}</p>
                    <div>
                      <l-map :zoom="zoom" :center="center">
                        <l-tile-layer :url="url" />
                        <l-marker :lat-lng="[latitude, longitude]" />
                      </l-map>
                    </div>
                  </div>
                </b-card>
              </b-col>
            </b-row>
          </b-card>
          <b-card bg-variant="light" v-if="asset.info_docs.length > 0">
            <div class="bg-light mb-2">
              <div class="font-weight-bolder mb-1">
                {{ $t("ManufacturesDocs") }}:
              </div>
              <div class="d-flex flex-wrap gap-1">
                <template>
                  <b-link
                    class="btn btn-outline-secondary word-break-all"
                    v-for="(doc, index) in files"
                    :key="index"
                    target="_blank"
                    :href="doc.path"
                  >
                    <feather-icon icon="FileIcon" />
                    {{ doc.name }}
                  </b-link>
                </template>
              </div>
            </div>
          </b-card>
          <b-card bg-variant="light" v-if="asset.production_access">
            <div class="bg-light mb-2">
              <div class="font-weight-bolder mb-1">
                {{ $t("Production") }}:
              </div>
              <div class="d-flex flex-wrap gap-1">
                <b-link
                    class="btn btn-outline-secondary"
                    :href="asset.production_access"
                    target="_blank"
                >
                  {{ $t('ProductionAccessLink') }}
                </b-link>
              </div>
            </div>
          </b-card>


          <b-card bg-variant="light">
            <div v-if="asset.observations" class="mb-2">
              <span class="font-weight-bolder">
                {{ $t("CoverObservations") }}:
              </span>
              <div v-html="asset.observations"></div>
            </div>

            <div class="d-flex flex-wrap mb-2">
              <div class="flex-grow-1">
                <div class="font-weight-bolder">{{ $t("CoverType") }}:</div>
                <span>{{
    asset.cover_type
      ? $t(`${capitalizeText(asset.cover_type)}`)
      : "--"
  }}</span>
                <span v-if="asset.cover_type === 'tilt'">
                  de: {{ asset.angle }}°
                </span>
              </div>
            </div>

            <div>
              <div class="font-weight-bolder mb-1">
                {{ $t("SecurityElements") }}:
              </div>
              <div class="d-flex flex-wrap gap-1 mb-1">
                <div :class="'d-inline-flex align-items-center rounded-pill ' +
    statusColorVariant(asset.linea_vida)
    ">
                  <b-img fluid :src="statusIconVariant(asset.linea_vida)" alt="LineaVida"
                    style="height: 20px; width: 20px" />
                  <span class="mb-0">
                    {{ $t("Línea de vida") }}
                  </span>
                </div>
                <div :class="'d-inline-flex align-items-center rounded-pill ' +
    statusColorVariant(asset.barandillas_seguridad)
    ">
                  <b-img fluid :src="statusIconVariant(asset.barandillas_seguridad)" alt="Barandilla"
                    style="height: 20px; width: 20px" />
                  <span class="mb-0">
                    {{ $t("Barandillas de seguridad") }}
                  </span>
                </div>
                <div :class="'d-inline-flex align-items-center rounded-pill ' +
    statusColorVariant(asset.redes_contencion)
    ">
                  <b-img fluid :src="statusIconVariant(asset.redes_contencion)" alt="RedesContencion"
                    style="height: 20px; width: 20px" />
                  <span class="mb-0">
                    {{ $t("Redes de contanción") }}
                  </span>
                </div>
                <div :class="'d-inline-flex align-items-center rounded-pill ' +
    statusColorVariant(asset.zona_delimitada)
    ">
                  <b-img fluid :src="statusIconVariant(asset.zona_delimitada)" alt="ZonaDelimitada"
                    style="height: 20px; width: 20px" />
                  <span class="mb-0">
                    {{ $t("Zona de trabajo delimitada") }}
                  </span>
                </div>
              </div>
              <div v-if="asset.security_elements">
                <div v-html="asset.security_elements"></div>
              </div>
            </div>
          </b-card>
          <b-card no-body bg-variant="light">
            <template v-if="components">
              <div class="p-1">
                <span class="font-weight-bolder">
                  {{ $t("Components") }}:
                </span>
              </div>
              <!-- :stacked="stacked" -->
              <b-table striped responsive :fields="fieldsComponents" :items="components" class="components-table"
                head-variant="dark-gray">
              </b-table>
            </template>
          </b-card>
        </b-card>
      </b-col>
      <!-- FACILITIES -->
      <b-col cols="12" class="ficha-localizacion d-lg-block">
        <b-card no-body>
          <b-row>
            <b-col sm="12" md="10">
              <b-tabs
                  style="font-weight: bolder"
                  lazy pills
                  nav-class="flex-column flex-md-row"
                  :align="(['xs', 'sm'].includes(breakpoint)) ? 'center' : 'start'"
                  @activate-tab="tab = $event"
              >
                <b-tab :active="tab === 0">
                  <template #title>
                    {{ $t("Actions") }}
                  </template>
                </b-tab>
                <b-tab :active="tab === 1">
                  <template #title>
                    {{ $t("NextActions") }}
                  </template>
                </b-tab>
                <b-tab :active="tab === 2">
                  <template #title>
                    {{ $t("ExternalActions") }}
                  </template>
                </b-tab>
              </b-tabs>
            </b-col>
            <b-col
              sm="12"
              md="2"
              class="d-flex justify-content-end align-self-center"
              :class="{ 'py-1': ['xs', 'sm'].includes(breakpoint) }"
              >
              <div v-if="tab === 2">
                <div v-show="['super_admin', 'admin_cliente', 'admin_empresa'].includes(currentRole)" >
                  <b-button
                    @click="externalActionModal = true"
                    variant="outline-secondary"
                    size="sm"
                  >
                    <span> {{ $t("AddAction") }}</span>
                  </b-button>
                </div>
              </div>
            <div v-else class="d-flex" >
              <span v-if="['xs', 'sm'].includes(breakpoint)" style="margin-right: 5px">
                {{ $t("Filters") }}
              </span>
              <feather-icon
                class="mr-1 cursor-pointer d-flex align-self-center"
                icon="FilterIcon"
                size="20"
                @click="visibleFilter = !visibleFilter"
              />
            </div>
            </b-col>
          </b-row>
          <template v-if="tab === 0">
            <ActionsTable :visibleFilter="visibleFilter" />
          </template>
          <template v-if="tab === 1">
            <NextActionsTable :visibleFilter="visibleFilter" />
          </template>
          <template v-if="tab === 2">
            <ExternalActionsTable />
          </template>
        </b-card>
      </b-col>
    </b-row>
    <CreateExternalActionModal v-model="externalActionModal" />
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Ripple from "vue-ripple-directive";
import { isUserLoggedIn } from "@/auth/utils";
import { config } from "@/shared/app.config";
import { LMap, LMarker, LTileLayer } from "vue2-leaflet";
import { latLng, Icon } from "leaflet";
import "leaflet/dist/leaflet.css";
import {
  TimestampToFlatPickr,
  TimestampToFlatPickrWithTime,
} from "@/libs/helpers";
import { capitalizeText } from "@/shared/helpers";
import ActionsTable from "@/components/actions/table/ActionsTable.vue";
import NextActionsTable from "@/components/actions/table/NextActionsTable.vue";
import { BTabs, BTab } from "bootstrap-vue";
import CreateExternalActionModal from "@/components/actions/modal/CreateExternalActionModal.vue"
import ExternalActionsTable from "@/components/actions/table/ExternalActionsTable.vue";

delete Icon.Default.prototype._getIconUrl;

Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

export default {
  components: {
    BTabs,
    BTab,
    LMap,
    LTileLayer,
    LMarker,
    ActionsTable,
    NextActionsTable,
    CreateExternalActionModal,
    ExternalActionsTable
  },
  directives: {
    Ripple,
  },
  mounted() {
    this.stacked = window.innerWidth <= 1400;
    window.addEventListener("resize", () => {
      this.stacked = window.innerWidth <= 1400;
    });
  },
  data() {
    return {
      tab: 0,
      capitalizeText,
      visibleInfo: false,
      statusAssets: config.statusAssetsVariants,
      stacked: false,
      visibleFilter: false,
      nowTimestamp: Math.floor(Date.now() / 1000),
      fieldsComponents: [
        { key: "units", label: "UDS." },
        { key: "component", label: this.$t("Component") },
        { key: "brand", label: this.$t("Brand") },
        { key: "model", label: this.$t("Modelo") },
      ],
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      urlLocation: "",
      zoom: 13,
      zoomLocation: 5,
      latitude: 0,
      longitude: 0,
      locActual: true,
      textEventLoc: "",
      assetPrepare: false,
      defaultImage: require("@/assets/images/default/asset.png"),
      externalActionModal: false
    };
  },
  computed: {
    ...mapGetters({
      asset: "assets/getAsset",
      items: "events/getItemsLogs",
      currentRole: "auth/getRole",
      breakpoint: "app/currentBreakPoint",
    }),
    statusIconVariant() {
      const statusIcon = {
        null: require("@/assets/images/icons/cancel.svg"),
        1: require("@/assets/images/icons/check.svg"),
        0: require("@/assets/images/icons/cancel.svg"),
      };

      return (status) => statusIcon[status];
    },
    statusColorVariant() {
      const statusColor = {
        null: "rounded-pill-gray",
        1: "rounded-pill-green",
        0: "rounded-pill-gray",
      };

      return (status) => statusColor[status];
    },
    canDownload() {
      if (this.asset && this.asset.status) {
        if (
          this.asset.status.alias === "incidence" ||
          this.asset.status.alias === "use"
        ) {
          return true;
        }
      }
      return false;
    },
    fileDownload() {
      if (this.asset && this.asset.status) {
        if (
          this.asset.status.alias === "incidence" &&
          this.asset.archives.incidence
        ) {
          return this.asset.archives.incidence.url;
        } else if (
          this.asset.status.alias === "use" &&
          this.asset.archives.delivery
        ) {
          return this.asset.archives.delivery.url;
        }
      }
      return false;
    },
    textDownload() {
      if (this.asset && this.asset.status) {
        if (
          this.asset.status.alias === "incidence" &&
          this.asset.archives.incidence
        ) {
          return this.$t("InformeIncidencia");
        } else if (
          this.asset.status.alias === "use" &&
          this.asset.archives.delivery
        ) {
          return this.$t("CertificadoEntrega");
        }
      }
      return "";
    },
    center() {
      return latLng(this.latitude, this.longitude);
    },
    images() {
      const imgs = [];
      if (this.asset.imagen) {
        imgs.push({ img: this.asset.imagen });
      }
      if (this.asset.documents.length > 0) {
        this.asset.documents.forEach(({ path }) => {
          imgs.push({ img: path });
        });
      }

      if (imgs.length == 0) imgs.push({ img: this.defaultImage });

      return imgs;
    },
    files() {
      const files = this.asset.info_docs.length > 0 ? this.asset.info_docs : [];

      return files;
    },
    isLogin() {
      return isUserLoggedIn();
    },
    clientContactPersons() {
      return this.asset.users_contact;
    },
    components() {
      return this.asset.components?.map((component) => JSON.parse(component));
    },
    appMode () {
      return this.$route.name === 'publicViewAsset'
    }
  },
  watch: {
    currentPage() {
      this.chargeData();
    },
    pageLength() {
      this.chargeData();
    },
    user() {
      this.currentPage = 1;
      this.chargeData();
    },
  },
  methods: {
    ...mapActions({
      getAsset: "assets/getAsset",
    }),
    toDate(f) {
      if (f) {
        return TimestampToFlatPickr(f);
      }
      return "";
    },
    toDateWithTime(f) {
      if (f) {
        return TimestampToFlatPickrWithTime(f);
      }
      return "";
    },
    openMap(latitude, longitude, text, hour) {
      this.latitude = latitude;
      this.longitude = longitude;
      this.locActual = false;
      this.textEventLoc = `${text} - ${hour}`;
    },
    setData() {
      if (this.asset.location_relation) {
        this.latitude = this.asset.location_relation.latitude;
        this.longitude = this.asset.location_relation.longitude;
      } else {
        this.latitude = "";
        this.longitude = "";
      }
      this.locActual = true;
      this.assetPrepare = true;
    },
    handleOpenMapLinkClick(asset) {
      this.openMap(
        asset.location?.latitude || asset.latitude,
        asset.location?.longitude || asset.longitude,
        this.$t(`typeevents.${asset.type}`),
        this.toDateWithTime(asset.created_at)
      );
    },
    priceFormatted(price) {
      let val = (price / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
  },
  async created() {
    await this.getAsset(this.$route.params.id);
    await this.setData();
  },
};
</script>
<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce-details.scss";
@import "@core/scss/vue/libs/vue-select.scss";


.dropdown-user{
  list-style-type: none;
}
[dir] .b-nav-dropdown .dropdown-toggle::after{
  display: none;
}

.vue2leaflet-map {
  &.leaflet-container {
    height: 350px;
  }
}

.components-table table thead tr th {
  background: lightgray !important;
  color: black !important;
}

.filter-icon {
  position: absolute;
  right: 20px;
  top: 15px;
}

.nav-pills {
  margin-bottom: 0;
}

.carousel-indicators {
  position: relative;
}

.carousel-indicators li {
  margin-top: 5px;
  background: black;
  border-radius: 50%;
  width: 9px;
  height: 10px;
  border-top: none;
  border-bottom: none;
}

.truncated-text {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}
</style>
